import { api } from "../services/api.service";
import { AxiosError } from "axios";

interface VerificationResponse {
  success: boolean;
  data?: {
    // Add specific response data type here
  };
  error?: {
    statusCode: number;
    message: string;
  };
}

export const discountCodeApi = {
  verifyCode: async (discountCode: string): Promise<VerificationResponse> => {
    try {
      const response = await api.get(`/code-verification/${discountCode}`);
      console.log({ response });

      return {
        success: true,
        // data: response.data
      };
    } catch (err) {
      console.error("Discount code verification error:", err);

      if (err instanceof AxiosError) {
        return {
          success: false,
          error: {
            statusCode: err.response?.status || 500,
            message:
              err.response?.data?.message || "Failed to verify discount code",
          },
        };
      }

      return {
        success: false,
        error: {
          statusCode: 500,
          message:
            err instanceof Error ? err.message : "An unexpected error occurred",
        },
      };
    }
  },
};
