import { Unit } from "../../types/unit";
import { useError } from "../../providers/ErrorProvider";
import { useState } from "react";
import { discountCodeApi } from "../../api/discount-code.api";
import { paymentIntentApi } from "../../api/payment-intent-api";
import { PaymentProvider } from "../../PaymentProvider";
import { PaymentForm } from "./PaymentForm";
import { BoundingBox } from "../../types";
import { formatArea } from "../../utils/distanceAreaFormatting";
import { CheckCircle2, CreditCard, Gift, Loader2 } from "lucide-react";

interface AreaMetrics {
  area: {
    km2: number;
  };
  dimensions: {
    width: number;
    height: number;
  };
}

interface PaymentFlowProps {
  boundingBox: BoundingBox;
  price: number;
  isEligibleForFreeTrial: boolean;
  metrics: AreaMetrics | null;
  selectedUnit: Unit;
  onSuccess: () => void;
  onClose: () => void;
}

export const PaymentFlow: React.FC<PaymentFlowProps> = ({
  boundingBox,
  price,
  isEligibleForFreeTrial,
  onSuccess,
  onClose,
  metrics,
  selectedUnit,
}) => {
  const { addError } = useError();
  const [isPurchaseInProgress, setIsPurchaseInProgress] =
    useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [discountCode, setDiscountCode] = useState("");
  const [isApplyingCode, setIsApplyingCode] = useState(false);
  const [discountError, setDiscountError] = useState<string | undefined>(
    undefined
  );
  const [discountCodeValid, setDiscountCodeValid] = useState(false);

  const handleApplyDiscount = async () => {
    if (!discountCode.trim()) return;
    setIsApplyingCode(true);
    setDiscountError("");
    setDiscountCodeValid(false);

    if (discountCode) {
      try {
        const response = await discountCodeApi.verifyCode(discountCode);

        if (!response.success) {
          setDiscountError(response.error?.message);
        } else {
          setDiscountCodeValid(true);
        }
      } catch (err) {
        console.log({ err });
      }
    }

    setIsApplyingCode(false);
  };

  const isEligibleForFreePurchase = () =>
    isEligibleForFreeTrial || discountCodeValid;

  const handlePurchase = async () => {
    setIsPurchaseInProgress(true);
    try {
      const response = await paymentIntentApi.createPaymentIntent({
        boundingBox,
        ...(discountCodeValid ? { discountCode } : {}),
      });

      if (response.freeTrialPurchase) {
        onSuccess();
      } else if (response.clientSecret) {
        setClientSecret(response.clientSecret);
      } else {
        addError("An unknown error has occurred. Please try later.", "error");
      }
    } catch (error) {
      addError(
        error instanceof Error
          ? error.message
          : "An unexpected error occurred while processing your purchase.",
        "error"
      );
    } finally {
      setIsPurchaseInProgress(false);
    }
  };

  if (clientSecret) {
    return (
      <div className="relative">
        <PaymentProvider clientSecret={clientSecret}>
          <PaymentForm
            boundingBox={boundingBox}
            onSuccess={onSuccess}
            onError={(msg: string) => addError(msg, "error")}
            clientSecret={clientSecret}
          />
        </PaymentProvider>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="space-y-4">
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex justify-between items-center mb-2">
            <span>Area size:</span>
            <span className="font-medium">
              {formatArea(metrics?.area.km2 ?? 0, selectedUnit)}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span>Price:</span>
            <span className="font-medium">
              {isEligibleForFreePurchase() ? "Free" : `$${price.toFixed(2)}`}
            </span>
          </div>
        </div>

        {!isEligibleForFreePurchase() && (
          <div className="space-y-2">
            <div className="flex gap-2">
              <input
                type="text"
                placeholder="Enter discount code"
                value={discountCode}
                onChange={(e) => {
                  setDiscountCode(e.target.value.toUpperCase());
                  setDiscountError(undefined);
                  setDiscountCodeValid(false);
                }}
                className="flex-1 px-3 py-2 border rounded-lg text-sm bg-white"
                disabled={isApplyingCode}
              />
              <button
                onClick={handleApplyDiscount}
                disabled={!discountCode.trim() || isApplyingCode}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg text-sm font-medium hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
              >
                {isApplyingCode ? "Checking..." : "Check"}
              </button>
            </div>
            {discountError && (
              <div className="p-2 text-sm text-red-600 bg-red-50 border border-red-200 rounded-lg">
                {discountError}
              </div>
            )}
          </div>
        )}

        {discountCodeValid && (
          <div className="flex items-center p-2 bg-green-50 border border-green-200 rounded-lg">
            <CheckCircle2 className="h-4 w-4 text-green-500 flex-shrink-0" />
            <span className="text-sm text-green-700 ml-2">
              Discount code valid!
            </span>
          </div>
        )}

        <button
          onClick={handlePurchase}
          disabled={isPurchaseInProgress}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg disabled:bg-gray-300 disabled:cursor-not-allowed flex items-center justify-center gap-2"
        >
          {isPurchaseInProgress ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              Processing...
            </>
          ) : (
            <>
              {isEligibleForFreePurchase() ? (
                <Gift className="h-4 w-4" />
              ) : (
                <CreditCard className="h-4 w-4" />
              )}

              {isEligibleForFreePurchase()
                ? "Get free file"
                : "Proceed to payment"}
            </>
          )}
        </button>
      </div>
    </div>
  );
};
