import React, { useState } from "react";
import {
  AlertTriangle,
  CreditCard,
  Maximize2,
  Check,
  Ruler,
  FileDown,
  AlertCircle,
  LucideIcon,
} from "lucide-react";
import { calculateAreaMetrics } from "./utils/areaCalculations";
import { AREA_LIMITS, BoundingBox, MapCoordinates } from "./types";
import { calculatePrice } from "./utils/pricing";
import { formatArea, formatDistance } from "./utils/distanceAreaFormatting";
import { useAuth } from "./store/AuthContext";
import DownloadStatus from "./DownloadStatus";
import { useUserProfile } from "./hooks/useUserProfile";
import Loading from "./components/Loading/LoadingComponent";
import { UnitSelector } from "./components/UnitSelector";
import { Unit } from "./types/unit";
import { UserProfile } from "./store/types/user.types";
import { Modal } from "./components/reusable/Modal";
import { FileFormat } from "./types/file-format";
import { PaymentFlow } from "./components/payment/PaymentFlow";

interface AreaMetrics {
  area: {
    km2: number;
  };
  dimensions: {
    width: number;
    height: number;
  };
}

interface ExportPanelProps {
  selectedArea: MapCoordinates;
  onPurchase: (params: { onSuccess: () => void }) => void;
  onExport: (params: { onSuccess: () => void }) => void;
}

interface AreaMetricsDisplayProps {
  metrics: AreaMetrics;
  selectedUnit: Unit;
  isAreaTooLarge: boolean | null;
  setSelectedUnit: (unit: Unit) => void;
}

interface MetricCardProps {
  label: string;
  value: string;
  icon: LucideIcon;
}

interface AreaTooLargeWarningProps {
  maxArea: string;
}

interface FormatSelectorProps {
  formats: FileFormat[];
  selectedFormat: string;
  onFormatSelect: (format: string) => void;
}

interface PriceDisplayProps {
  price: number;
  isEligibleForFreeTrial: boolean;
  profile: UserProfile | undefined;
  isAuthenticated: boolean;
  selectedUnit: Unit;
  selectedArea: number;
}

const supportedFormats: FileFormat[] = [
  { id: "dxf", name: "AutoCAD DXF", available: true },
  { id: "svg", name: "SVG Vector", available: false },
];

const MetricCard: React.FC<MetricCardProps> = ({
  label,
  value,
  icon: Icon,
}) => (
  <div className="bg-white p-3 rounded border border-gray-200">
    <div className="text-gray-600 mb-1 flex items-center gap-2">
      <Icon className="h-4 w-4" />
      {label}
    </div>
    <div className="font-medium">{value}</div>
  </div>
);

const AreaTooLargeWarning: React.FC<AreaTooLargeWarningProps> = ({
  maxArea,
}) => (
  <div className="mt-3 p-3 bg-red-50 border border-red-200 rounded-lg text-red-700 flex items-center gap-2">
    <AlertTriangle className="h-5 w-5" />
    <div>
      <p className="font-medium">Area too large</p>
      <p className="text-sm">
        Maximum allowed area is {maxArea}. Please select a smaller area.
      </p>
      <p className="text-xs italic mt-3">
        For larger area requests, please reach out to our support.
      </p>
    </div>
  </div>
);

const FormatSelector: React.FC<FormatSelectorProps> = ({
  formats,
  selectedFormat,
  onFormatSelect,
}) => (
  <div>
    <label className="text-sm font-medium flex items-center gap-2">
      <FileDown className="h-4 w-4" />
      Select Format:
    </label>
    <div className="grid grid-cols-2 gap-3 mt-2">
      {formats.map((format) => (
        <button
          key={format.id}
          onClick={() => onFormatSelect(format.id)}
          className={`p-3 rounded-lg border ${
            selectedFormat === format.id
              ? "border-blue-500 bg-blue-50"
              : "border-gray-200"
          } ${!format.available ? "opacity-50 cursor-not-allowed" : ""}`}
          disabled={!format.available}
        >
          <div className="font-medium">{format.name}</div>
          {!format.available && (
            <div className="text-xs text-gray-500">Coming soon</div>
          )}
        </button>
      ))}
    </div>
  </div>
);

const PriceDisplay: React.FC<PriceDisplayProps> = ({
  price,
  isEligibleForFreeTrial,
  profile,
  isAuthenticated,
  selectedUnit,
}) => (
  <div className="space-y-2">
    <div className="bg-gray-50 rounded-lg border border-gray-200 p-4">
      <div
        className={`flex items-center justify-between mb-${
          isEligibleForFreeTrial ? "2" : "0"
        }`}
      >
        <div className="flex items-center gap-2">
          <CreditCard className="h-5 w-5" />
          <div className="font-medium">Price</div>
        </div>
        <div className="text-xl font-bold">
          {isEligibleForFreeTrial ? (
            <div className="font-medium flex items-center gap-2">Free</div>
          ) : (
            <div className="font-medium">${price.toFixed(2)}</div>
          )}
        </div>
      </div>

      <DownloadStatus
        isAuthenticated={isAuthenticated}
        profile={profile}
        selectedUnit={selectedUnit}
        isEligibleForFreeTrial={isEligibleForFreeTrial}
      />
    </div>
  </div>
);

const AreaMetricsDisplay: React.FC<AreaMetricsDisplayProps> = ({
  metrics,
  selectedUnit,
  isAreaTooLarge,
  setSelectedUnit,
}) => (
  <div className="p-4 border-b border-gray-200 bg-gray-50">
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center gap-2">
        <Ruler className="h-5 w-5 text-gray-600" />
        <span className="font-medium">Selected Area</span>
      </div>
      <UnitSelector
        selectedUnit={selectedUnit}
        onUnitChange={setSelectedUnit}
      />
    </div>

    <div className="grid grid-cols-2 gap-4 text-sm">
      <MetricCard
        label="Area"
        value={formatArea(metrics.area.km2, selectedUnit)}
        icon={Maximize2}
      />
      <MetricCard
        label="Dimensions"
        value={`${formatDistance(
          metrics.dimensions.width,
          selectedUnit
        )} × ${formatDistance(metrics.dimensions.height, selectedUnit)}`}
        icon={Ruler}
      />
    </div>

    {isAreaTooLarge && (
      <AreaTooLargeWarning
        maxArea={formatArea(AREA_LIMITS.MAX_AREA, selectedUnit, 0)}
      />
    )}
  </div>
);

export const ExportPanel: React.FC<ExportPanelProps> = ({
  selectedArea,
  onExport,
}) => {
  const {
    state: { isAuthenticated },
  } = useAuth();
  const { profile, isLoading, refetch: refetchUserProfile } = useUserProfile();
  const [selectedFormat, setSelectedFormat] = useState<string>("dxf");
  const [selectedUnit, setSelectedUnit] = useState<Unit>(Unit.KM);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  if (isLoading) return <Loading />;

  const metrics = selectedArea.bounds
    ? calculateAreaMetrics(selectedArea.bounds)
    : null;
  const { price, isEligibleForFreeTrial } = calculatePrice(
    profile,
    metrics?.area.km2
  );

  const isAreaTooLarge = metrics && metrics.area.km2 > AREA_LIMITS.MAX_AREA;

  const handleExportClick = () => {
    onExport({
      onSuccess: () => setShowPaymentModal(true),
    });
  };

  const handlePaymentSuccess = () => {
    setShowPaymentModal(false);
    setShowSuccessModal(true);
    refetchUserProfile();
  };

  const boundingBox = selectedArea.bounds
    ? {
        southwest: {
          lat: selectedArea.bounds.getSouthWest().lat,
          lon: selectedArea.bounds.getSouthWest().lng,
        },
        northeast: {
          lat: selectedArea.bounds.getNorthEast().lat,
          lon: selectedArea.bounds.getNorthEast().lng,
        },
      }
    : null;

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      {metrics && (
        <AreaMetricsDisplay
          metrics={metrics}
          selectedUnit={selectedUnit}
          isAreaTooLarge={isAreaTooLarge}
          setSelectedUnit={setSelectedUnit}
        />
      )}

      <div className="p-4 space-y-4">
        {!isAreaTooLarge && (
          <FormatSelector
            formats={supportedFormats}
            selectedFormat={selectedFormat}
            onFormatSelect={setSelectedFormat}
          />
        )}

        {metrics && !isAreaTooLarge && (
          <PriceDisplay
            price={price}
            isEligibleForFreeTrial={isEligibleForFreeTrial}
            profile={profile}
            isAuthenticated={isAuthenticated}
            selectedUnit={selectedUnit}
            selectedArea={metrics.area.km2}
          />
        )}

        <button
          className={`w-full py-2 px-4 rounded-lg transition-colors flex items-center justify-center gap-2 ${
            isAreaTooLarge || !metrics
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          }`}
          disabled={isAreaTooLarge || !metrics}
          onClick={handleExportClick}
        >
          {isAreaTooLarge ? (
            <>
              <AlertCircle className="h-4 w-4" />
              Area Too Large
            </>
          ) : (
            <>
              <FileDown className="h-4 w-4" />
              Export Selected Area
            </>
          )}
        </button>
      </div>

      <Modal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        title={
          <div className="flex items-center gap-2">
            <CreditCard className="h-5 w-5" />
            Complete Purchase
          </div>
        }
      >
        {boundingBox && (
          <PaymentFlow
            boundingBox={boundingBox}
            price={price}
            isEligibleForFreeTrial={isEligibleForFreeTrial}
            onSuccess={handlePaymentSuccess}
            onClose={() => setShowPaymentModal(false)}
            selectedUnit={selectedUnit}
            metrics={metrics}
          />
        )}
      </Modal>

      <Modal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        title={
          <div className="flex items-center gap-2">
            <Check className="h-5 w-5 text-green-500" />
            Purchase Successful
          </div>
        }
      >
        <div className="p-4">
          <p>
            Your purchase was successful! You will receive your file by e-mail
            shortly.
          </p>
          <button
            onClick={() => setShowSuccessModal(false)}
            className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center justify-center gap-2"
          >
            <Check className="h-4 w-4" />
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};
